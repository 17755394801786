import useSWR from 'swr'
import Api from '../../services/Api'
import { bcApiURL } from '../../ApiEndpoints'

export const useGetProductById = (productIds) => {
  const getKey = () => {
    // Below url has type query params that is only for SWR to identify url uniquely.
    // That is not a valid query params for Bigcommerce.
    return productIds ? `${bcApiURL}?id=${productIds}` : null
  }

  const { data, error, mutate } = useSWR(() => getKey(), getProducts)

  const isLoading = (!data && !error && productIds) || false
  const products = data || []

  async function getProducts(url) {
    const body = {
      url: 'v3/catalog/products',
      query_params: {
        'id:in': productIds,
        include: 'images',
      },
      method: 'get',
      body: {},
    }

    const response = await Api.post(url, body)
    return response.data
  }

  return {
    products,
    isLoading,
    mutate,
  }
}

export default useGetProductById
