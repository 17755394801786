import { Link } from "gatsby";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import useGetProductById from "../../hooks/products/useGetProductById";
import useToastify from "../../hooks/ui/useToastify";
import { getCompareProducts, setCompareCookie } from "../../utils/product";
import { decrement } from "../../redux/counter";
import Button from "../form/button/Button";
import Loader from "../form/Loader";
import Image from "../common/Image";

function CompareProducts() {
  const [productId, setProductId] = useState(null);
  const productIds = getCompareProducts();
  const dispatch = useDispatch();

  const filterdProductIds =
    productId !== null
      ? productIds.filter((val) => {
        return parseInt(val) !== productId;
      })
      : productIds;

  const { products, isLoading } = useGetProductById(
    filterdProductIds?.join(",")
  );
  const { toastMessage } = useToastify();

  const removeProduct = (id) => {
    setProductId(id);
    setCompareCookie(id, toastMessage);
    dispatch(decrement());
  };

  if (isLoading) {
    return <Loader />;
  } else if (products?.length) {
    return (
      <>
        <div className="page-wrapper">
          <div className="page-heading-section">
            <h1 className="page-title">Compare {`${products?.length}`}</h1>
          </div>
        </div>

        <div className="container">
          <h4>
            {products.length === 1
              ? `Please add one more product for comparision`
              : ""}
          </h4>

          <div className="page-layout">
            <div>{`COMPARING ${products.length} PRODUCTS`}</div>

            <table className="compare-table">
              <tbody>
                <tr className="compareTable-row">
                  <td className="compareTable-heading"></td>

                  {products.map((CProduct) => {
                    return (
                      <td key={CProduct.name} className="compare-product-image">
                        <Link href={CProduct?.custom_url?.url}>
                          <Image
                            src={
                              (CProduct?.images?.length &&
                                CProduct.images[0].url_standard) ||
                              "/img/default-bc-product.png"
                            }
                            alt={CProduct?.name}
                          />
                        </Link>
                      </td>
                    );
                  })}
                </tr>

                <tr className="compareTable-row">
                  <td className="compareTable-heading"></td>
                  {products.map((CProduct) => {
                    return (
                      <td key={CProduct.name}>
                        <Link to={CProduct?.custom_url?.url}>
                          {CProduct.name}
                        </Link>
                      </td>
                    );
                  })}
                </tr>

                <tr className="compareTable-row">
                  <td className="compareTable-heading">Price</td>

                  {products.map((CProduct) => {
                    return (
                      <td key={CProduct.name}>
                        <p className="cPrice">{CProduct.price}</p>
                      </td>
                    );
                  })}
                </tr>

                <tr className="compareTable-row">
                  <td className="compareTable-heading">Availability</td>

                  {products.map((CProduct) => {
                    return (
                      <>
                        <td key={CProduct.name}>{CProduct.availability}</td>
                      </>
                    );
                  })}
                </tr>
                <tr className="compareTable-row">
                  <td className="compareTable-heading"></td>

                  {products.map((CProduct) => {
                    return (
                      <>
                        <td key={CProduct.name}>
                          <div className="card-compare-button">
                            <Button
                              onClick={() => {
                                removeProduct(CProduct.id);
                              }}
                            >
                              Remove
                            </Button>
                          </div>
                        </td>
                      </>
                    );
                  })}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  } else {
    return <h4>{`Please add products for comparision.`}</h4>;
  }
}

export default CompareProducts;
