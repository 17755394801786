import React from "react";
import CompareProducts from "../components/products/CompareProducts";
import { staticPage } from "../../config/staticPage";
import Layout from "../layout/Layout";

function ComparePage() {
  return (
    <CompareProducts />
  );
}
export const Head = () => {
  let login_nav = staticPage;
  let loginDetails = null;
  for (const obj of login_nav) {
    if (obj.name === "compare") {
      loginDetails = obj;
      break; // Exit the loop once the desired object is found.
    }
  }
  return (
    <>
      <title>{loginDetails.title}</title>
      <meta name="description" content={loginDetails.description} />
    </>
  );
};

ComparePage.Layout = Layout
export default ComparePage;
